/* Theme customization */

.adm-card {
    -webkit-box-shadow: 1px 5px 14px 2px rgba(0,0,0,0.52); 
box-shadow: 1px 5px 14px 2px rgba(0,0,0,0.06);
    /* box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05); */
    border-radius: 24px!important;
}

.ant-typography:not(.ant-typography-secondary) {
    color : #041257;
}
.ant-drawer-header-title {
    /* switch icon from left to right */
    flex-direction: row-reverse!important
}


